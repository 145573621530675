import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";
import moment from "moment";

const buildTblLuaran = (luaran = []) => {
  const layout = {
    style: ["center"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 1,
    },
    table: {
      widths: ["7%", "22%", "30%", "21%", "20%"],
      body: [
        [
          {
            text: "Tahun Luaran",
            style: ["center"],
          },
          {
            text: "Kategori Luaran",
            style: ["center"],
          },
          {
            text: "Jenis Luaran",
            style: ["center"],
          },
          {
            text: "Status target capaian",
            style: ["center"],
          },
          {
            text: "Keterangan",
          },
        ],
      ],
    },
  };
  luaran
    .sort((a, b) => a.tahun_kegiatan - b.tahun_kegiatan)
    .forEach(item => {
      layout.table.body.push([
        {
          text: item.tahun_kegiatan,
        },
        {
          text: item.raw?.kategori_luaran,
        },
        {
          text: item.raw?.jenis_luaran,
        },
        {
          text: item.raw?.status_capaian,
        },
        {
          text: item.keterangan || "-",
        },
      ]);
    });
  return layout;
};

const buildTblIdentitas1 = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["25%", "30%", "25%", "20%"],
      body: [
        [
          {
            text: `Bidang Fokus`,
            style: ["center"],
          },
          {
            text: `Tema`,
            style: ["center"],
          },
          {
            text: `Topik (jika ada)`,
            style: ["center"],
          },
          {
            text: `Prioritas Riset`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  const idsSkemaKerjasamaLn = [103, 142];
  const rows = [
    {
      text: data.nama_bidang_fokus || "-",
    },
    {
      text: data.nama_tema || "-",
    },
    {
      text: data.nama_topik || "-",
    },
    {
      text: `${data.nama_tema_prioritas_riset || "-"} `,
    },
  ];
  if (idsSkemaKerjasamaLn.includes(parseInt(data.id_skema))) {
    layout.table.widths = ["20%", "25%", "20%", "20%", "15%"];
    layout.table.body[0].push({
      text: `Program Kerjasama Luar Negeri`,
      style: ["center"],
    });
    rows.push({
      text: `${data.program_kerjasama_ln || "-"}`,
    });
  }

  layout.table.body.push(rows);

  return layout;
};

const buildTblRumpunIlmu = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["30%", "35%", "35%"],
      body: [
        [
          {
            text: `Rumpun Ilmu Level 1`,
            style: ["center"],
          },
          {
            text: `Rumpun Ilmu Level 2`,
            style: ["center"],
          },
          {
            text: `Rumpun Ilmu Level 3`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  const rows = [
    {
      text: data.rumpun_ilmu_lvl_1 || "-",
    },
    {
      text: data.rumpun_ilmu_lvl_2 || "-",
    },
    {
      text: data.rumpun_ilmu_lvl_3 || "-",
    },
  ];

  layout.table.body.push(rows);

  return layout;
};

const buildTblIdentitas2 = (data = {}) => {
  const layout = {
    style: ["center"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      widths: ["25%", "24%", "22%", "15%", "15%"],
      body: [
        [
          {
            text: `Skema Penelitian`,
            style: ["center"],
          },
          {
            text: `Strata (Dasar/Terapan/Pengembangan)`,
            style: ["center"],
          },
          {
            text: `Nilai SBK`,
            style: ["center"],
          },
          {
            text: `Target Akhir TKT`,
            style: ["center"],
          },
          {
            text: `Lama Kegiatan`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: data.nama_skema,
    },
    {
      text: data.nama_kategori_penelitian,
    },
    {
      text: utilities.toCurrency(data.nilai_sbk, ".", 0, ""),
    },
    {
      text: `${data.tkt.tkt_target}`,
    },
    {
      text: `${data.lama_kegiatan} Tahun`,
    },
  ]);
  return layout;
};

const buildTblPengusul = (personil_dosen = [], personil_non_dosen) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["30%", "10%", "23%", "25%", "12%"],
      body: [
        [
          {
            text: `Nama, Peran`,
            style: ["center"],
          },
          {
            text: `Jenis`,
            style: ["center"],
          },
          {
            text: `Program Studi/Bagian `,
            style: ["center"],
          },
          {
            text: `Bidang Tugas`,
            style: ["center"],
          },
          {
            text: `ID Sinta`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  personil_dosen
    .sort((a, b) => (a.urutan > b.urutan ? 1 : b.urutan > a.urutan ? -1 : 0))
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.nama}\n${item.nidn} \n\n ${item.nama_peran} \n${item?.raw?.institusi}`,
          style: ["center"],
        },
        {
          text: `${
            item.raw?.jenis_keanggotaan === "dosen" ? "Dosen" : "Dosen TPM"
          }`,
          style: ["center"],
        },

        {
          text: `${item?.raw?.prodi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item.uraian_tugas || "-"}`,
        },
        {
          text: `${item?.id_sinta || "-"}`,
          link: `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`,
          style: ["center", "link"],
        },
      ]);
    });

  personil_non_dosen
    .filter(row => row.kind === "umum")
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.raw?.nama}\n${item.raw?.nik} \n\n ${item.raw?.nama_peran}  \n${item?.raw?.institusi}`,
          style: ["center"],
        },
        {
          text: `Umum`,
          style: ["center"],
        },
        {
          text: `${item?.raw?.bidang_keahlian || "-"}`,
          style: ["center"],
        },
        {
          text: `${item.raw?.uraian_tugas || "-"}`,
        },
        {
          text: `${"-"}`,
          style: ["center"],
        },
      ]);
    });

  personil_non_dosen
    .filter(row => row.kind === "mahasiswa")
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.raw?.nama}\n${item.raw?.nim} \n\n ${item.raw?.nama_peran} \n${item?.raw?.institusi}`,
          style: ["center"],
        },
        {
          text: `Mahasiswa`,
          style: ["center"],
        },
        {
          text: `${item?.raw?.prodi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item.raw?.uraian_tugas || "-"}`,
        },
        {
          text: `${"-"}`,
          style: ["center"],
        },
      ]);
    });

  personil_non_dosen
    .filter(row => row.kind === "mitra")
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.raw?.nama}\n${item.raw?.no_identitas} \n\n ${item.raw?.nama_peran} \n${item?.raw?.instansi}`,
          style: ["center"],
        },
        {
          text: `Mitra`,
          style: ["center"],
        },
        {
          text: `${"-"}`,
          style: ["center"],
        },
        {
          text: `${item.raw?.uraian_tugas || "-"}`,
        },
        {
          text: `${"-"}`,
          style: ["center"],
        },
      ]);
    });

  return layout;
};

const buildTblAnggaran = (data = []) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["25%", "15%", "25%", "10%", "5%", "15%", "15%"],
      body: [
        [
          {
            text: `Kelompok`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Item`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Vol.`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Biaya Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Total`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };

  //soring by kelompok rab
  data
    .sort((a, b) =>
      a.raw?.no_urut_kelompok > b.raw?.no_urut_kelompok
        ? 1
        : b.raw?.no_urut_kelompok > a.raw?.no_urut_kelompok
        ? -1
        : 0
    )
    .forEach(item => {
      const volume = parseInt(item.volume);
      const harga_satuan = utilities.unmaskMoney(item.harga_satuan);
      const total = volume * harga_satuan;

      const tblBodyItem = [
        {
          text: `${item.raw.nama_kelompok_rab}`,
        },
        {
          text: `${item.raw.rab_komponen_belanja}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_item}`,
          style: ["center"],
        },
        {
          text: `${item.satuan || "-"}`,
          style: ["center"],
        },
        {
          text: `${volume || "-"}`,
          style: ["center"],
        },
        {
          text: `${utilities.toCurrency(harga_satuan || 0, ".", 0)}`,
          style: ["right"],
        },
        {
          text: `${utilities.toCurrency(total || 0, ".", 0)}`,
          style: ["right"],
        },
      ];

      layout.table.body.push(tblBodyItem);
    });

  return layout;
};

const buildTblMitra = (mitra = []) => {
  const layout = {
    style: ["text", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      widths: ["30%", "35%", "35%"],
      body: [
        [
          {
            text: `Mitra`,
            style: ["center"],
          },
          {
            text: `Nama Mitra`,
            style: ["center"],
          },

          {
            text: `Dana`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  mitra.forEach(row => {
    let dana = `Tahun 1 ${utilities.toCurrency(
      row.kontribusi_tahun_1?.toString()?.split(".")?.join(""),
      ".",
      2,
      "Rp"
    )}`;
    if (row.kontribusi_tahun_2) {
      dana += `\n Tahun 2 ${utilities.toCurrency(
        row.kontribusi_tahun_2?.toString()?.split(".")?.join(""),
        ".",
        2,
        "Rp. "
      )}`;
    }
    if (row.kontribusi_tahun_3) {
      dana += `\n Tahun 2 ${utilities.toCurrency(
        row.kontribusi_tahun_3?.toString()?.split(".")?.join(""),
        ".",
        2,
        "Rp. "
      )}`;
    }
    layout.table.body.push([
      {
        text: `${row.institusi}`,
      },
      {
        text: `${row.nama_mitra}`,
      },
      {
        text: dana,
      },
    ]);
  });

  return layout;
};

const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
  let thnAkhirUsulan = parseInt(thnPertamaUsulan);
  for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
    thnAkhirUsulan += tahun;
  }
  return thnAkhirUsulan;
};

const content = (data, _pageBreak = false) => {
  const { hr } = stylePDF;
  const {
    raw: { identitas: identitas_usulan, mitra },
    personil,
    personil_non_dosen,
    rab,
    luaran,
    rab_revisi,
  } = data;

  let anggaran = utilities.groupBy(
    rab_revisi || rab || [],
    "urutan_tahun_kegiatan"
  );

  const totalAnggaran = rab.reduce(
    (a, { harga_satuan, volume }) =>
      a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
    0
  );

  const luaranTambahanExist = luaran.filter(item => !item.is_wajib).length > 0;
  return [
    header(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `PROPOSAL PENELITIAN ${
            identitas_usulan.tahun_pelaksanaan ?? ""
          }`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `Rencana Pelaksanaan Penelitian: tahun ${
            identitas_usulan?.tahun_pelaksanaan ?? ""
          } s.d. tahun ${getThnAkhirUsulan(
            identitas_usulan.lama_kegiatan,
            identitas_usulan.tahun_pelaksanaan
          )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    {
      stack: [
        {
          text: "1. JUDUL PENELITIAN",
          style: ["bold"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: identitas_usulan.judul,
                },
              ],
            ],
          },
        },

        buildTblIdentitas1(identitas_usulan),
        buildTblRumpunIlmu(identitas_usulan),
        buildTblIdentitas2(identitas_usulan),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS PENGUSUL",
          style: ["bold"],
        },
        buildTblPengusul(personil, personil_non_dosen),
      ],
      style: ["mb-10"],
    },
    data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
      ? {
          stack: [
            {
              text: "3. DOKUMEN PENDUKUNG", //jika skema terapan (dikti) label jika ada tidak tampil
              style: ["bold"],
            },
            {
              text: "URL Artikel di jurnal sebagai penulis pertama (first author) atau penulis korespondensi (corresponding author) yang relevan dengan usulan penelitian",
            },
            {
              text: data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama,
              link: data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama,
              style: ["link", "mb-10"],
            },
          ],
        }
      : null,
    {
      stack: [
        {
          text:
            `${
              data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
                ? "4."
                : "3."
            } MITRA KERJASAMA PENELITIAN` +
            (data.id_skema === 150 ? "" : " (Jika Ada)"), //jika skema terapan (dikti) label jika ada tidak tampil
          style: ["bold"],
        },
        {
          text: "Pelaksanaan penelitian dapat melibatkan mitra kerjasama yaitu mitra kerjasama dalam melaksanakan penelitian, mitra sebagai calon pengguna hasil penelitian, atau mitra investor",
          style: ["justify"],
        },
        buildTblMitra(mitra),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text:
            (data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
              ? "5."
              : "4.") + "  LUARAN DAN TARGET CAPAIAN",
          style: ["bold"],
        },
        {
          text: "Luaran Wajib",
          style: ["bold", "mt-10"],
        },
        buildTblLuaran(luaran.filter(l => l.is_wajib)),
        luaranTambahanExist
          ? {
              text: "Luaran Tambahan",
              style: ["bold", "mt-10"],
            }
          : {},
        luaranTambahanExist
          ? buildTblLuaran(luaran.filter(l => !l.is_wajib))
          : {},
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text:
            (data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
              ? "6."
              : "5.") + " ANGGARAN",
          style: ["bold"],
        },
        {
          text: "Rencana Anggaran Biaya penelitian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
          style: ["justify", "mb-10"],
        },
        {
          text: `Total RAB ${
            Object.keys(anggaran)?.length || 0
          } Tahun ${utilities.toCurrency(totalAnggaran, ".", 2, "Rp")}`,
          style: ["bold", "mb-10"],
        },
      ],
    },
    {
      stack: [
        ...(Object.entries(anggaran) || []).map(([key, anggaranItem]) => {
          const totalAnggaranPertahun =
            anggaranItem.reduce(
              (a, b) =>
                parseInt(a) +
                utilities.unmaskMoney(b.harga_satuan) * parseInt(b.volume),
              0
            ) || 0;
          return {
            style: ["mb-10"],
            stack: [
              {
                text: `Tahun ${key} Total ${utilities.toCurrency(
                  totalAnggaranPertahun,
                  ".",
                  2,
                  "Rp"
                )}`,
                style: ["bold"],
              },
              buildTblAnggaran(
                anggaranItem.filter(item => item.harga_satuan !== 0)
              ),
            ],
          };
        }),
      ],
    },
  ];
};

const contentPersetujuan = (data = {}) => {
  let { approval_raw, approval_status } = data;
  approval_raw = approval_raw ?? {};

  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: `${
        data?.tgl_updated
          ? moment(data?.tgl_updated)
              .zone(data?.tgl_updated)
              .format("DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${
        approval_raw?.approval_date
          ? moment(approval_raw?.approval_date)
              .zone(approval_raw?.approval_date)
              .format("DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.full_name || "-"}`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.nama_jabatan || "-"}`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-10"],
      stack: [
        {
          text: `Komentar : ${
            approval_status === "approved"
              ? "Disetujui"
              : approval_status === "rejected"
              ? "Ditolak"
              : "-"
          }`,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (approval_raw?.comment ? 0.3 : 0),
            vLineWidth: _i => (approval_raw?.comment ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: approval_raw?.comment,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
const contentProposalForLaporanKemajuanPenelitianV2 = (
  data,
  dataRevisi,
  _pageBreak = false
) => {
  const {
    raw: { identitas: identitas_usulan, mitra },
    personil,
    personil_non_dosen,
    luaran,
  } = data;
  const rab_revisi = dataRevisi.rab_revisi || [];
  let anggaran = utilities.groupBy(rab_revisi || [], "urutan_tahun_kegiatan");

  const totalAnggaran = rab_revisi.reduce(
    (a, { harga_satuan, volume }) =>
      a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
    0
  );

  const luaranTambahanExist = luaran.filter(item => !item.is_wajib).length > 0;
  return [
    {
      stack: [
        {
          text: "1. JUDUL PENELITIAN",
          style: ["bold"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: identitas_usulan.judul,
                },
              ],
            ],
          },
        },

        buildTblIdentitas1(identitas_usulan),
        buildTblRumpunIlmu(identitas_usulan),
        buildTblIdentitas2(identitas_usulan),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS PENGUSUL",
          style: ["bold"],
        },
        buildTblPengusul(personil, personil_non_dosen),
      ],
      style: ["mb-10"],
    },
    data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
      ? {
          stack: [
            {
              text: "3. DOKUMEN PENDUKUNG", //jika skema terapan (dikti) label jika ada tidak tampil
              style: ["bold"],
            },
            {
              text: "URL Artikel di jurnal sebagai penulis pertama (first author) atau penulis korespondensi (corresponding author) yang relevan dengan usulan penelitian",
            },
            {
              text: data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama,
              link: data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama,
              style: ["link", "mb-10"],
            },
          ],
        }
      : null,
    {
      stack: [
        {
          text:
            `${
              data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
                ? "4."
                : "3."
            } MITRA KERJASAMA PENELITIAN` +
            (data.id_skema === 150 ? "" : " (Jika Ada)"), //jika skema terapan (dikti) label jika ada tidak tampil
          style: ["bold"],
        },
        {
          text: "Pelaksanaan penelitian dapat melibatkan mitra kerjasama yaitu mitra kerjasama dalam melaksanakan penelitian, mitra sebagai calon pengguna hasil penelitian, atau mitra investor",
          style: ["justify"],
        },
        buildTblMitra(mitra),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text:
            (data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
              ? "5."
              : "4.") + "  LUARAN DAN TARGET CAPAIAN",
          style: ["bold"],
        },
        {
          text: "Luaran Wajib",
          style: ["bold", "mt-10"],
        },
        buildTblLuaran(luaran.filter(l => l.is_wajib)),
        luaranTambahanExist
          ? {
              text: "Luaran Tambahan",
              style: ["bold", "mt-10"],
            }
          : {},
        luaranTambahanExist
          ? buildTblLuaran(luaran.filter(l => !l.is_wajib))
          : {},
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text:
            (data?.raw?.dokumen_pendukung?.url_artikel_penulis_pertama
              ? "6."
              : "5.") + " ANGGARAN",
          style: ["bold"],
        },
        {
          text: "Rencana Anggaran Biaya penelitian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
          style: ["justify", "mb-10"],
        },
        {
          text: `Total RAB ${
            Object.keys(anggaran)?.length || 0
          } Tahun ${utilities.toCurrency(totalAnggaran, ".", 2, "Rp")}`,
          style: ["bold", "mb-10"],
        },
      ],
    },
    {
      stack: [
        ...(Object.entries(anggaran) || []).map(([key, anggaranItem]) => {
          const totalAnggaranPertahun =
            anggaranItem.reduce(
              (a, b) =>
                parseInt(a) +
                utilities.unmaskMoney(b.harga_satuan) * parseInt(b.volume),
              0
            ) || 0;
          return {
            style: ["mb-10"],
            stack: [
              {
                text: `Tahun ${key} Total ${utilities.toCurrency(
                  totalAnggaranPertahun,
                  ".",
                  2,
                  "Rp"
                )}`,
                style: ["bold"],
              },
              buildTblAnggaran(
                anggaranItem.filter(item => item.harga_satuan !== 0)
              ),
            ],
          };
        }),
      ],
    },
  ];
};
export {
  contentPersetujuan,
  content,
  buildTblAnggaran,
  contentProposalForLaporanKemajuanPenelitianV2,
};
